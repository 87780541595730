import React, { useContext, useEffect, useState } from "react";
import SummaryApi from "../common";
import Context from "../context";
import displayINRCurrency from "../helpers/displayCurrency";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "./CartStyle.css";

const Cart = () => {
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [newTotalPrice, setNewTotalPrice] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const context = useContext(Context);
  const loadingCart = new Array(4).fill(null);

  const fetchData = async () => {
    const token = localStorage.getItem("authToken");
    const response = await fetch(SummaryApi.addToCartProductView.url, {
      method: SummaryApi.addToCartProductView.method,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();

    if (responseData.success) {
      setData(responseData.data);
      setSubTotal(responseData.data.reduce(
        (preve, curr) => preve + curr.quantity * curr?.productId?.sellingPrice,
        0
      ));

      setNewTotalPrice(responseData.data.reduce(
        (preve, curr) => preve + curr.quantity * curr?.productId?.sellingPrice,
        0
      ));
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
  }, []);

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleAdressChange = (e) => {
    setAddress(e.target.value);
  };

  const increaseQty = async (id, qty) => {
    const token = localStorage.getItem("authToken");
    const response = await fetch(SummaryApi.updateCartProduct.url, {
      method: SummaryApi.updateCartProduct.method,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id: id, quantity: qty + 1 }),
    });

    const responseData = await response.json();
    if (responseData.success) {
      fetchData();
    }
  };

  const decraseQty = async (id, qty) => {
    const token = localStorage.getItem("authToken");
    if (qty >= 2) {
      const response = await fetch(SummaryApi.updateCartProduct.url, {
        method: SummaryApi.updateCartProduct.method,
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: id, quantity: qty - 1 }),
      });

      const responseData = await response.json();
      if (responseData.success) {
        fetchData();
      }
    }
  };

  const deleteCartProduct = async (id) => {
    const token = localStorage.getItem("authToken");
    const response = await fetch(SummaryApi.deleteCartProduct.url, {
      method: SummaryApi.deleteCartProduct.method,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id: id }),
    });

    const responseData = await response.json();
    if (responseData.success) {
      fetchData();
      context.fetchUserAddToCart();
    }
  };

  const applyCouponHandler = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await fetch(SummaryApi.applyCoupon.url, {
        method: SummaryApi.applyCoupon.method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ couponCode, cartTotal: subTotal }),
      });

      const result = await response.json();
      if (result.success) {
        setDiscount(result.discountAmount);
        setNewTotalPrice(result.newTotal);
        setIsCouponApplied(true);
        toast.success('Coupon applied successfully!');
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      toast.error('Error applying coupon. Please try again later.');
    }
  };

  const placeOrderHandler = async () => {
    const token = localStorage.getItem("authToken");

    if (!address || phone.length < 10 || phone[0] === "0" || !city || data.length < 1) {
      toast.error("Please enter your address and phone number correctly. Cart should not be empty.");
      return;
    } else {
      for (const item of data) {
        const productQuantity = item.quantity;
        const productName = item.productId.productName;
        const userId = item.userId;
        const productId = item.productId._id;

        try {
          const response = await fetch(SummaryApi.placeOrder.url, {
            method: "POST",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              address,
              productQuantity,
              productName,
              phone,
              userId,
              productId,
              city,
              totalPrice: newTotalPrice, // Apply discount to totalPrice
            }),
          });

          const data = await response.json();
          if (data.success) {
            deleteCartProduct(item._id);
          } else {
            alert(`Error: ${data.message}`);
          }
        } catch (error) {
          console.error(`Error placing order for product ${item.productId.productName}:`, error);
          alert("There was an error placing the order. Please try again later.");
        }
      }
      setAddress("");
      setCity("");
      setPhone('');
      toast.success("Order placed successfully. We will send your invoice shortly to your email.");
    }
  };

  const totalQty = data.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0
  );

  return (
    <div className="container mx-auto mt-8">
      <div className="text-center text-lg my-3">
        {data.length === 0 && !loading && (
          <p className="bg-white py-5 text-lg font-bold underline outline-1 outline-slate-300">No Orders</p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-10 lg:justify-between p-2 pt-5">
        <div className="w-full max-w-3xl">
          {loading
            ? loadingCart?.map((el, index) => (
                <div
                  key={el + "Add To Cart Loading" + index}
                  className="w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded"
                ></div>
              ))
            : data.map((product) => (
                <div
                  key={product?._id + "Add To Cart Loading"}
                  className="w-full bg-zinc-800 h-37 my-2 border border-slate-300 rounded grid grid-cols-[128px,1fr]"
                >
                  <div className="w-32 h-29 bg-slate-300 p-1 m-1">
                    <img
                      src={product?.productId?.productImage[0]}
                      className="w-full h-32 object-fill mix-blend-multiply"
                    />
                  </div>
                  <div className="px-4 py-2 relative">
                    <div
                      className="absolute right-0 text-red-600 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer"
                      onClick={() => deleteCartProduct(product?._id)}
                    >
                      <MdDelete />
                    </div>

                    <h2 className="text-lg lg:text-xl font-bold text-gray-500 text-ellipsis line-clamp-1">
                      {product?.productId?.productName}
                    </h2>
                    <p className="capitalize text-slate-500 font-medium">
                      {product?.productId.category}
                    </p>
                    <div className="flex items-center justify-between">
                      <p className="text-red-600 font-medium text-lg">
                        {displayINRCurrency(product?.productId?.sellingPrice)}
                      </p>
                      <p className="text-white font-semibold text-lg">
                        {displayINRCurrency(
                          product?.productId?.sellingPrice * product?.quantity
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-1">
                      <button
                        className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded"
                        onClick={() =>
                          decraseQty(product?._id, product?.quantity)
                        }
                      >
                        -
                      </button>
                      <span className="text-white">{product?.quantity}</span>
                      <button
                        className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded"
                        onClick={() =>
                          increaseQty(product?._id, product?.quantity)
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))}
        </div>

        <div className="mt-5 lg:mt-0 w-full max-w-sm">
          {loading ? (
            <div className="h-36 bg-slate-200 border border-slate-300 animate-pulse"></div>
          ) : (
            <div className="cart_container h-full">
              <div className="cart_card cart">
                <label className="cart_title">CHECKOUT</label>
                <div className="steps">
                  <div className="step">
                    <div>
                      <span>PAYMENT METHOD</span>
                      <p>Cash on Delivery</p>
                    </div>
                    <hr className="cart_hr" />
                    <div className="promo">
                      <form>
                        <input
                          type="text"
                          value={address}
                          onChange={handleAdressChange}
                          placeholder="Address"
                          required
                          className="input_field"
                        />
                      </form>
                      <form>
                        <input
                          type="text"
                          value={city}
                          onChange={handleCityChange}
                          placeholder="City"
                          required
                          className="input_field"
                        />
                      </form>
                      <form>
                        <div className="phone-input-container">
                          <span className="phone-prefix" style={{ borderRightWidth: "0px", borderRadius: "5px 0px 0px 5px" }}>+92</span>
                          <input
                            type="Number"
                            style={{ borderLeftWidth: "0px", borderRadius: "0px 5px 5px 0px", marginTop: "-3px", padding: "3px", }}
                            value={phone}
                            onChange={handlePhoneChange}
                            placeholder="Phone Number"
                            required
                            className="input_field phone-number-input"
                          />
                        </div>
                      </form>
                      <form className="my-4">
                        <input
                          type="text"
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value)}
                          placeholder="Enter Coupon Code"
                          disabled={isCouponApplied}
                          className="input_field"
                        />
                        <button
                          type="button"
                          onClick={applyCouponHandler}
                          disabled={isCouponApplied}
                          className="apply-coupon-btn"
                        >
                          Apply
                        </button>
                      </form>
                    </div>
                    <hr />
                    <div className="payments">
                      <span>PAYMENT</span>
                      <div className="details">
                        <span>Product Quantity:</span>
                        <span>{totalQty}</span>
                        <span>Subtotal:</span>
                        <span>{displayINRCurrency(subTotal)}</span>
                        <span>Delivery Charges:</span>
                        <span>200.00</span>
                        <span>Discount:</span>
                        <span>{displayINRCurrency(discount)}</span>
                        <span>Total:</span>
                        <span>{displayINRCurrency(newTotalPrice + 200 )}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cart_card cart_checkout">
                <div className="cart_footer">
                  <label className="cart_price">
                    {displayINRCurrency(newTotalPrice + 200)}
                  </label>
                  <button
                    className="cart_checkout-btn"
                    onClick={placeOrderHandler}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
