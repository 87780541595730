import React, { useEffect, useState, useContext } from "react";
import { MdDelete } from "react-icons/md";
import Context from "../context";
import { toast } from "react-toastify";
import "./CartStyle.css";
import displayINRCurrency from "../helpers/displayCurrency";
import SummaryApi from "../common";
const GuestCart = () => {
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [newTotalPrice, setNewTotalPrice] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const { setCartProductCount } = useContext(Context); 
  // Fetch products from localStorage
  const fetchData = () => {
    setLoading(true);
    const products = JSON.parse(localStorage.getItem("products")) || [];
    
    // Set default quantity if not present
    const updatedProducts = products.map(product => {
      if (!product.quantity) {
        return { ...product, quantity: 1 }; // Default quantity to 1
      }
      return product;
    });
    
    setData(updatedProducts);
    setSubTotal(updatedProducts.reduce(
      (preve, curr) => preve + curr.quantity * curr.sellingPrice, 0
    ));
    setNewTotalPrice(updatedProducts.reduce(
      (preve, curr) => preve + curr.quantity * curr.sellingPrice, 0
    ));
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    console.log("guest cart");
  }, []);

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleAdressChange = (e) => {
    setAddress(e.target.value);
  };
  const handleNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleMailChange = (e) => {
    setEmail(e.target.value);
  };

  // Increase quantity and update in localStorage
  const increaseQty = (id) => {
    const updatedData = data.map((product) => {
      if (product._id === id) {
        return { ...product, quantity: product.quantity + 1 };
      }
      return product;
    });

    localStorage.setItem("products", JSON.stringify(updatedData));
    fetchData(); // Re-fetch the updated data
  };

  // Decrease quantity and update in localStorage
  const decraseQty = (id) => {
    const updatedData = data.map((product) => {
      if (product._id === id && product.quantity > 1) {
        return { ...product, quantity: product.quantity - 1 };
      }
      return product;
    });

    localStorage.setItem("products", JSON.stringify(updatedData));
    fetchData(); // Re-fetch the updated data
  };

  // Delete product from cart and update in localStorage
  const deleteCartProduct = (id) => {
    const updatedData = data.filter((product) => product._id !== id);
    localStorage.setItem("products", JSON.stringify(updatedData));
    setCartProductCount(updatedData.length);
    fetchData(); // Re-fetch the updated data
  };

  const totalQty = data.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0
  );

  const applyCouponHandler = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await fetch(SummaryApi.applyCoupon.url, {
        method: SummaryApi.applyCoupon.method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ couponCode, cartTotal: subTotal }),
      });

      const result = await response.json();
      if (result.success) {
        setDiscount(result.discountAmount);
        setNewTotalPrice(result.newTotal);
        setIsCouponApplied(true);
        toast.success('Coupon applied successfully!');
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      toast.error('Error applying coupon. Please try again later.');
    }
  };

  const placeOrderHandler = async () => {

    if (!address || phone.length < 10 || phone[0] === "0" || !city || data.length < 1 ) {
      toast.error("Please enter your address and phone number correctly. Cart should not be empty.");
      return;
    } else {
      for (const item of data) {
        const productQuantity = item.quantity;
        const productName = item.productName;
        const userId = "66bf44119c7a4090bc681ed9";
        const productId = item._id;
        const name = userName;
        const mail = email;
        try {
          const response = await fetch(SummaryApi.placeOrder.url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name,
              mail,
              address,
              productQuantity,
              productName,
              phone,
              userId,
              productId,
              city,
              totalPrice: newTotalPrice, // Apply discount to totalPrice
            }),
          });

          const data = await response.json();
          if (data.success) {
            deleteCartProduct(item._id);
          } else {
            alert(`Error: ${data.message}`);
          }
        } catch (error) {
          console.error(`Error placing order for product ${item.productId.productName}:`, error);
          alert("There was an error placing the order. Please try again later.");
        }
      }
      setAddress("");
      setCity("");
      setPhone('');
      setUserName('');
      setEmail('');
      toast.success("Order placed successfully. We will send your invoice shortly to your email.");
      localStorage.removeItem("products");
      setCartProductCount("0");
      fetchData();
    }
  };
  return (
    <div className="container mx-auto mt-8">
      <div className="text-center text-lg my-3">
        {data.length === 0 && !loading && (
          <p className="bg-white py-5 text-lg font-bold underline outline-1 outline-slate-300">No Orders</p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-10 lg:justify-between p-2 pt-5">
        <div className="w-full max-w-3xl">
          {loading
            ? new Array(4).fill(null).map((_, index) => (
                <div
                  key={index}
                  className="w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded"
                ></div>
              ))
            : data.map((product) => (
                <div
                  key={product._id}
                  className="w-full bg-zinc-800 h-37 my-2 border border-slate-300 rounded grid grid-cols-[128px,1fr]"
                >
                  <div className="w-32 h-29 bg-slate-300 p-1 m-1">
                    <img
                      src={product.productImage[0]}
                      className="w-full h-32 object-fill mix-blend-multiply"
                    />
                  </div>
                  <div className="px-4 py-2 relative">
                    <div
                      className="absolute right-0 text-red-600 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer"
                      onClick={() => deleteCartProduct(product._id)}
                    >
                      <MdDelete />
                    </div>

                    <h2 className="text-lg lg:text-xl font-bold text-gray-500 text-ellipsis line-clamp-1">
                      {product.productName}
                    </h2>
                    <p className="capitalize text-slate-500 font-medium">
                      {product.category}
                    </p>
                    <div className="flex items-center justify-between">
                      <p className="text-red-600 font-medium text-lg">
                        {displayINRCurrency(product.sellingPrice)}
                      </p>
                      <p className="text-white font-semibold text-lg">
                        {displayINRCurrency(product.sellingPrice * product.quantity)}
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-1">
                      <button
                        className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded"
                        onClick={() => decraseQty(product._id)}
                      >
                        -
                      </button>
                      <span className="text-white">{product.quantity}</span>
                      <button
                        className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded"
                        onClick={() => increaseQty(product._id)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))}
        </div>

        <div className="mt-5 lg:mt-0 w-full max-w-sm">
          {loading ? (
            <div className="h-36 bg-slate-200 border border-slate-300 animate-pulse"></div>
          ) : (
            <div className="cart_container h-full">
              <div className="cart_card cart">
                <label className="cart_title">CHECKOUT</label>
                <div className="steps">
                  <div className="step">
                    <div>
                      <span>PAYMENT METHOD</span>
                      <p>Cash on Delivery</p>
                    </div>
                    <hr className="cart_hr" />
                    <div className="promo">
                      <form>
                      <input
                          type="text"
                          value={userName}
                          onChange={handleNameChange}
                          placeholder="Name"
                          required
                          className="input_field"
                        />
                        <input
                          type="email"
                          value={email}
                          onChange={handleMailChange}
                          placeholder="Email"
                          required
                          className="input_field"
                        />
                        <input
                          type="text"
                          value={address}
                          onChange={handleAdressChange}
                          placeholder="Address"
                          required
                          className="input_field"
                        />
                      </form>
                      <form>
                        <input
                          type="text"
                          value={city}
                          onChange={handleCityChange}
                          placeholder="City"
                          required
                          className="input_field"
                        />
                      </form>
                      <form>
                        <div className="phone-input-container">
                          <span className="phone-prefix" style={{ borderRightWidth: "0px", borderRadius: "5px 0px 0px 5px" }}>+92</span>
                          <input
                            type="Number"
                            style={{ borderLeftWidth: "0px", borderRadius: "0px 5px 5px 0px", marginTop: "-3px", padding: "3px", }}
                            value={phone}
                            onChange={handlePhoneChange}
                            placeholder="Phone Number"
                            required
                            className="input_field phone-number-input"
                          />
                        </div>
                      </form>
                      <form className="my-4">
                        <input
                          type="text"
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value)}
                          placeholder="Enter Coupon Code"
                          disabled={isCouponApplied}
                          className="input_field"
                        />
                        <button
                          type="button"
                          onClick={applyCouponHandler}
                          disabled={isCouponApplied}
                          className="apply-coupon-btn"
                        >
                          Apply
                        </button>
                      </form>
                    </div>
                    <hr />
                    <div className="payments">
                      <span>PAYMENT</span>
                      <div className="details">
                        <span>Product Quantity:</span>
                        <span>{totalQty}</span>
                        <span>Subtotal:</span>
                        <span>{displayINRCurrency(subTotal)}</span>
                        <span>Delivery Charges:</span>
                        <span>200.00</span>
                        <span>Discount:</span>
                        <span>{displayINRCurrency(discount)}</span>
                        <span>Total:</span>
                        <span>{displayINRCurrency(newTotalPrice + 200 )}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cart_card cart_checkout">
                <div className="cart_footer">
                  <label className="cart_price">
                    {displayINRCurrency(newTotalPrice + 200)}
                  </label>
                  <button
                    className="cart_checkout-btn"
                    onClick={placeOrderHandler}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuestCart;
