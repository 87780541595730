import logo from './logo.svg';
import './App.css';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import SummaryApi from './common';
import Context from './context';
import { useDispatch } from 'react-redux';
import { setUserDetails } from './store/userSlice';

function App() {
  const dispatch = useDispatch()
  const [cartProductCount,setCartProductCount] = useState(0)

  const fetchUserDetails = async()=>{
    const token = localStorage.getItem('authToken');
      const dataResponse = await fetch(SummaryApi.current_user.url,{
        method : SummaryApi.current_user.method,
        credentials : 'include',
        headers: {
          'Authorization': `Bearer ${token}`, // Send token in Authorization header
          'Content-Type': 'application/json',
      },
      })

      const dataApi = await dataResponse.json()

      if(dataApi.success){
        dispatch(setUserDetails(dataApi.data))
      }
  }

  const fetchUserAddToCart = async()=>{
    if(!localStorage.getItem('authToken')){
      const updateCartCount = () => {
        const products = JSON.parse(localStorage.getItem('products')) || [];
        setCartProductCount(products.length);
      };
  
      // Initial load - Check local storage and set count
      updateCartCount();
  
      // Add a listener for storage changes
      window.addEventListener('storage', updateCartCount);
  
      // Clean up listener on unmount
      return () => window.removeEventListener('storage', updateCartCount);
    }

      else{ 
        const token = localStorage.getItem('authToken');
    const dataResponse = await fetch(SummaryApi.addToCartProductCount.url,{
      method : SummaryApi.addToCartProductCount.method,
      credentials : 'include',
      headers: {
        'Authorization': `Bearer ${token}`, // Send token in Authorization header
        'Content-Type': 'application/json',
    },
    })

    const dataApi = await dataResponse.json()

    setCartProductCount(dataApi?.data?.count)
      }
    
  }

  useEffect(()=>{
  
    fetchUserDetails()
    fetchUserAddToCart()

  },[])
  return (
    <>
      <Context.Provider value={{
          fetchUserDetails, // user detail fetch 
          cartProductCount,
          setCartProductCount, // current user add to cart product count,
          fetchUserAddToCart
      }}>
        <ToastContainer 
          position='top-center'
          pauseOnFocusLoss={false}
          autoClose={2000}
        />
        <Header/>
        <main className='min-h-[calc(100vh-120px)] pt-16'>
          <Outlet/>
        </main>
        <Footer/>
      </Context.Provider>
    </>
  );
}

export default App;
