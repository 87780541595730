import React from 'react'
import CategoryList from '../components/CategoryList'
import BannerImage from '../components/BannerImage'
// import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'

const Home = () => {
  return (
    <div className='mt-5'>
      <CategoryList/>
      <BannerImage/>

      {/* <HorizontalCardProduct category={"bracelet"} heading={"Best Bracelets"}/>
      <HorizontalCardProduct category={"chain"} heading={"Popular Chains"}/> */}
      <VerticalCardProduct category={"bracelet"} heading={"Bracelets"}/>
      <VerticalCardProduct category={"necklace"} heading={"Necklaces"}/>
      <VerticalCardProduct category={"chain"} heading={"Chains"}/>
      

    </div>
  )
}

export default Home