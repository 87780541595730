import SummaryApi from "../common";
import { toast } from 'react-toastify';

// Modified function to accept setCartProductCount
const addToCartGuest = async (e, id, setCartProductCount) => {
  e?.stopPropagation();
  e?.preventDefault();

  const response = await fetch(SummaryApi.productDetails.url, {
    method: SummaryApi.productDetails.method,
    headers: {
      "content-type": 'application/json'
    },
    body: JSON.stringify({ productId: id })
  });

  const responseData = await response.json();
  
  if (responseData.success) {
    toast.success(responseData.message);

    let products = JSON.parse(localStorage.getItem("products")) || [];
    const productExists = products.some((product) => product._id === responseData.data._id);

    if (!productExists) {
      products.push(responseData.data);
      toast.success("Added to cart");
      localStorage.setItem("products", JSON.stringify(products));

      // Update the cart count
      setCartProductCount(products.length); // Update the product count here
    } else {
      toast.error("Already exists in cart");
    }
  }

  if (responseData.error) {
    toast.error(responseData.message);
  }

  return;
};

export default addToCartGuest;
