import React, { useState } from 'react'
import loginIcons from '../assest/signin.gif'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import './LoginSignupStyle.css'
import Logo from '../assest/rustam_white_wo_border.png';
const SignUp = () => {
  const [showPassword,setShowPassword] = useState(false)
  const [showConfirmPassword,setShowConfirmPassword] = useState(false)
  const [data,setData] = useState({
      email : "",
      password : "",
      name : "",
      confirmPassword : "",
      profilePic : "",
  })
  const navigate = useNavigate()

  const handleOnChange = (e) =>{
      const { name , value } = e.target

      setData((preve)=>{
          return{
              ...preve,
              [name] : value
          }
      })
  }



  const handleSubmit = async(e) =>{
      e.preventDefault()

      if(data.password === data.confirmPassword){

        const dataResponse = await fetch(SummaryApi.signUP.url,{
            method : SummaryApi.signUP.method,
            headers : {
                "content-type" : "application/json"
            },
            body : JSON.stringify(data)
          })
    
          const dataApi = await dataResponse.json()

          if(dataApi.success){
            toast.success(dataApi.message)
            navigate("/login")
          }

          if(dataApi.error){
            toast.error(dataApi.message)
          }
    
      }else{
        toast.error("Please check password and confirm password")
      }

  }

  return (


<section id='SignUp'>

<div className="flex items-center justify-center flex-col mt-16 h-full w-full relative z-[1]">
  <form method="post" className="form" onSubmit={handleSubmit}>
    <div className="form-body">
      <div className="welcome-lines">
        <div className="welcome-line-1">  <img src={Logo} alt="Rustam" className=' w-auto h-24 mx-auto' /> </div>
        <div className="welcome-line-2">Welcome</div>
      </div>
      <div className="input-area">
      <div className="form-inp">
          <input  type='text' 
                                    placeholder='Name' 
                                    name='name'
                                    value={data.name}
                                    onChange={handleOnChange}
                                    required/>
                                    
        </div>
        <div className="form-inp">
          <input  type='email' 
                                    placeholder='Email Address' 
                                    name='email'
                                    value={data.email}
                                    onChange={handleOnChange}/>
        </div>
        <div className="form-inp flex p-2">
          <input type={showPassword ? "text" : "password"} 
                                    placeholder='enter password'
                                    value={data.password}
                                    name='password' 
                                    onChange={handleOnChange} 
                                    required />
                                    <div className='cursor-pointer text-xl' onClick={()=>setShowPassword((preve)=>!preve)}>
                                    <span>
                                        {
                                            showPassword ? (
                                                <FaEyeSlash/>
                                            )
                                            :
                                            (
                                                <FaEye/>
                                            )
                                        }
                                    </span>
                                </div>
        </div>
        <div className="form-inp flex p-2">
          <input type={showConfirmPassword ? "text" : "password"} 
                                    placeholder='Confirm password'
                                    value={data.confirmPassword}
                                    name='confirmPassword' 
                                    onChange={handleOnChange} 
                                    required />
                                    <div className='cursor-pointer text-xl' onClick={()=>setShowConfirmPassword((preve)=>!preve)}>
                                    <span>
                                        {
                                            showConfirmPassword ? (
                                                <FaEyeSlash/>
                                            )
                                            :
                                            (
                                                <FaEye/>
                                            )
                                        }
                                    </span>
                                </div>
        </div>
      </div>
      <div className="submit-button-cvr">
        <button className="submit-button" type="submit">SignUp</button>
      </div>
    </div>

  </form>
  <p className='text-lg my-2 mx-auto text-center text-white border border-xl border-x-0 border-t-0 px-2'>Already have account ? <Link to={"/login"} className=' text-red-600 hover:text-red-700 hover:underline'>Login</Link></p>

  </div>



        {/* </div> */}
    </section>
  )
}

export default SignUp