import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const AllOrders = () => {
  const [user, setUser] = useState({});
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadingCart = new Array(4).fill(null);

  // Function to group orders by date
  const groupOrdersByDate = (orders) => {
    return orders.reduce((acc, order) => {
      const date = new Date(order.createdAt).toISOString().slice(0, 16); // Slice to get only the date part
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(order);
      return acc;
    }, {});
  };

  const fetchUserOrders = async () => {
    const token = localStorage.getItem('authToken');
    setLoading(true);
    const response = await fetch(SummaryApi.getUserOrders.url, {
      method: SummaryApi.getUserOrders.method,
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const responseData = await response.json();

    if (responseData.success) {
      toast.success("Got your orders");
      setOrders(responseData.data);
    } else {
      toast.error(responseData.message);
    }
  };

  const fetchUserDetails = async () => {
    const token = localStorage.getItem('authToken');
    const dataResponse = await fetch(SummaryApi.current_user.url, {
      method: SummaryApi.current_user.method,
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const dataApi = await dataResponse.json();

    if (dataApi.success) {
      setUser(dataApi.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserOrders();
    fetchUserDetails();
  }, []);

  const groupedOrders = groupOrdersByDate(orders);

  return (
    <div>
      <div className='w-full p-1 outline outline-amber-300 rounded '>
        {loading ? (
          loadingCart.map((el, index) => (
            <div
              key={index}
              className='w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded '
            ></div>
          ))
        ) : (
          Object.keys(groupedOrders).length > 0 ? (
            Object.keys(groupedOrders).map((date) => (
              <div key={date} className='w-full bg-white my-2 border border-slate-300 rounded '>
                <div className='px-4 py-2 font-bold'>
                  <p className='text-slate-500'>Date: {`${date.slice(0, 10)} Time: ${date.slice(11, 16)} `}</p>

                  <p className='text-slate-500 '>User Name: {user?.name}</p>
                  <p className='text-slate-500'>User Email: {user?.email}</p>
        
                  <p className="text-slate-500">Address: {groupedOrders[date][0].address}</p>
                  <p className="text-slate-500">City: {groupedOrders[date][0].city}</p>
                  <p className="text-slate-500">Phone Number: {groupedOrders[date][0].phoneNumber}</p>
                  <p className="text-slate-500">Total amount: {groupedOrders[date][0].total}</p>
                    

                </div>

                {/* Display all products for the specific date */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {groupedOrders[date].map((product) => (
                    <div
                      key={product?._id}
                      className="w-full bg-white my-2 border border-slate-300 rounded overflow-hidden"
                    >
                      <div className="grid grid-cols-1 sm:grid-cols-[128px,1fr]">
                        <div className="w-32 bg-slate-200">
                          <p className="mx-4 my-4">{product.productName}</p>
                        </div>
                        <div className="px-4 py-2 relative">
                          <p className="text-slate-500">Quantity: {product.quantity}</p>
                          <p className="text-slate-500">Order Id: {product._id}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p className='text-slate-500'>No orders found.</p>
          )
        )}
      </div>
    </div>
  );
};

export default AllOrders;
