import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const AllOrders = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadingCart = new Array(4).fill(null);
  const [count, setCount] = useState(1);
  const fetchData = async () => {
    const token = localStorage.getItem('authToken');

    const response = await fetch(SummaryApi.getOrders.url, {
      method: SummaryApi.getOrders.method,
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const responseData = await response.json();

    if (responseData) {
      toast.success("Got all orders");
      setOrders(responseData.data);
    }

    const fetchUsers = await fetch(SummaryApi.allUser.url, {
      method: SummaryApi.allUser.method,
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const dataResponse = await fetchUsers.json();

    if (dataResponse.success) {
      toast.success("Got all users");
      setAllUsers(dataResponse.data);
    }

    if (dataResponse.error) {
      toast.error(dataResponse.message);
    }
  };

  // const handleDeleteOrders = async (userId) => {
  //   try {
  //     const response = await fetch(`${SummaryApi.deleteOrder.url}${userId}`, {
  //       method: SummaryApi.deleteOrder.method,
  //       credentials: "include",
  //       headers: {
  //         "authorization": `Bearer ${localStorage.getItem("authToken")}`,
  //         "content-type": "application/json",
  //       },
  //       body: JSON.stringify({ userId: userId }),
  //     });

  //     const data = await response.json();

  //     if (response.ok) {
  //       toast.success(data.message);
  //       setOrders(orders.filter(order => order.userId !== userId));
  //     } else {
  //       toast.error(data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting orders:", error);
  //   }
  // };

  const getStatusColor = (status) => {
    switch (status) {
      case 'checked':
        return '#f0f4f8'; 
      case 'booked':
        return '#fcd34d'; 
      case 'sent':
        return '#60a5fa'; 
      case 'delivered':
        return '#34d399'; 
      default:
        return '#ffffff'; 
    }
  };
  
  const handleStatusChange = async (orderid, newStatus) => {
    const status = newStatus;
    const orderId = orderid;
    try {
  
      
      const response = await fetch(`${SummaryApi.updateStatus.url}}`, {
        method:SummaryApi.updateStatus.method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status, orderId}),
      });
  
      const data = await response.json();
   
      
      if (response.ok) {
        toast.success("Order status updated");
        setOrders(orders.map(order => order._id === orderId ? { ...order, status: newStatus } : order));
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error("Failed to update order status");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Group orders by userId and then by date and time (up to minutes)
  const groupedOrders = allUsers.reduce((acc, user) => {
    const userOrders = orders.filter(order => order.userId === user._id);
    if (userOrders.length > 0) {
      acc[user._id] = userOrders.reduce((dateAcc, order) => {
        // Format date and time up to minutes
        const dateTime = new Date(order.createdAt);
        const dateKey = dateTime.toDateString();
        const timeKey = `${dateTime.getHours()}:${dateTime.getMinutes() < 10 ? '0' : ''}${dateTime.getMinutes()}`;
        const dateTimeKey = `${dateKey} ${timeKey}`;
        
        if (!dateAcc[dateTimeKey]) {
          dateAcc[dateTimeKey] = [];
        }
        dateAcc[dateTimeKey].push(order);
        return dateAcc;
      }, {});
    }
    return acc;
  }, {});

  return (
    <div>
      <div className='w-full'>

        {loading ? (
          loadingCart.map((el, index) => (
            <div
              key={el + "Add To Cart Loading" + index}
              className='w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded'
            ></div>
          ))
        ) : (
          
          allUsers.map((user) => {
            const userOrders = groupedOrders[user._id];

            if (userOrders) {
              return (
                <div
                  key={user._id + "UserOrders"}
                  className='w-full bg-white my-2 border border-slate-300 rounded'
                >
                  <div className='px-4 py-2'>
                    <p className='text-slate-500 font-bold'>User Name: {user.name}</p>
                    <p className='text-slate-500 font-bold'>User Email: {user.email}</p>
                  </div>

                  {/* Render orders grouped by date and time */}
                  {Object.keys(userOrders).map((dateTime, index) => (
                    <div key={dateTime} className="my-4">
                  
                      <h3 className="text-lg font-semibold mb-2">Date & Time: {dateTime}</h3>
                      <div className="w-full bg-white border border-slate-300 rounded">
                        <div className='px-4 py-2'>
                          {
                             userOrders[dateTime][0]?.userName ? (
                              <p className='text-slate-500'>Name: {userOrders[dateTime][0]?.userName}</p>
                             ) : (
                               <div></div>
                             )
                          }

                          {
                             userOrders[dateTime][0]?.userEmail ? (
                              <p className='text-slate-500'>Email: {userOrders[dateTime][0]?.userEmail}</p>
                             ) : (
                               <div></div>
                             )
                          }
                        
                          <p className='text-slate-500'>Address: {userOrders[dateTime][0]?.address}</p>
                          <p className='text-slate-500'>City: {userOrders[dateTime][0]?.city}</p>
                          <p className='text-slate-500'>Phone Number: {userOrders[dateTime][0]?.phoneNumber}</p>
                          <p className='text-slate-500'>Total amount: {userOrders[dateTime][0]?.total}</p>
                        
                          <select
                          value={userOrders[dateTime][0]?.status}
                          onChange={(e) => handleStatusChange(userOrders[dateTime][0]?._id, e.target.value)}
                          className='mt-2 text-gray-700 border border-gray-300 rounded'
                          style={{ backgroundColor: getStatusColor(userOrders[dateTime][0]?.status) }}
                        >
                          <option value="checked">Checked</option>
                          <option value="booked">Booked</option>
                          <option value="sent">Sent</option>
                          <option value="delivered">Delivered</option>
                        </select>
                          {/* <button
                            onClick={() => handleDeleteOrders(user._id)}
                            className='mt-4 bg-red-500 text-white px-4 py-2 rounded'>
                            Delete All Orders
                          </button> */}
                        </div>

                        {/* Display all products ordered at this date and time */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {userOrders[dateTime].map((product) => (
                            <div
                              key={product?._id + "ProductDetails"}
                              className="w-full bg-white my-2 border border-slate-300 rounded overflow-hidden"
                            >
                              <div className="grid grid-cols-1 sm:grid-cols-[128px,1fr]">
                                <div className="w-32 bg-slate-200">
                                  <p className="mx-4 my-4 font-bold">{product.productName}</p>
                                </div>
                                <div className="px-4 py-2 relative">
                                  <p className="text-slate-500">Quantity: {product.quantity}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            }
            return null; // If no orders for the user, return null
          })
        )}
      </div>
    </div>
  );
};

export default AllOrders;
