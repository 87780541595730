import React, { useCallback, useContext, useEffect, useState } from 'react'
import  { useNavigate, useParams } from 'react-router-dom'
import SummaryApi from '../common'
// import { FaStar } from "react-icons/fa";
// import { FaStarHalf } from "react-icons/fa";
import { toast } from 'react-toastify'
import displayINRCurrency from '../helpers/displayCurrency';
import addToCartGuest from '../helpers/addToCartGuest'
import CategroyWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import addToCart from '../helpers/addToCart';
import Context from '../context';

const ProductDetails = () => {
  const [data,setData] = useState({
    productName : "",
    brandName : "",
    category : "",
    productImage : [],
    description : "",
    price : "",
    sellingPrice : ""
  })
  const params = useParams()

  // const productImageListLoading = new Array(1).fill(null)
  const [activeImage,setActiveImage] = useState("")

  const [zoomImageCoordinate,setZoomImageCoordinate] = useState({
    x : 0,
    y : 0
  })
  const [zoomImage,setZoomImage] = useState(false)

  const { fetchUserAddToCart } = useContext(Context)

  const navigate = useNavigate()

  const fetchProductDetails = async()=>{
    const response = await fetch(SummaryApi.productDetails.url,{
      method : SummaryApi.productDetails.method,
      headers : {
        "content-type" : "application/json"
      },
      body : JSON.stringify({
        productId : params?.id
      })
    })

    const dataReponse = await response.json()

    setData(dataReponse?.data)
    setActiveImage(dataReponse?.data?.productImage[0])

  }



  useEffect(()=>{
    fetchProductDetails()
  }, [params?.id])

  const handleMouseEnterProduct = (imageURL)=>{
    setActiveImage(imageURL)
  }

  const handleZoomImage = useCallback((e) =>{
    setZoomImage(true)
    const { left , top, width , height } = e.target.getBoundingClientRect()
 

    const x = (e.clientX - left) / width
    const y = (e.clientY - top) / height

    setZoomImageCoordinate({
      x,
      y
    })
  },[])

  const handleLeaveImageZoom = ()=>{
    setZoomImage(false)
  }


  const { setCartProductCount } = useContext(Context);
    const handleAddToCart = async (e, id) => {
      if (!localStorage.getItem("authToken")) {
      console.log("called from verticalcard component");
      await addToCartGuest(e, id,setCartProductCount);
    }
    else{
      await addToCart(e,id);
      
      fetchUserAddToCart(); 
    }
    
    };

  const handleBuyProduct = async(e,id)=>{
    if (!localStorage.getItem("authToken")) {
      await addToCartGuest(e, id, setCartProductCount);
      navigate("/Guestcart")
  }
    else{
    await addToCart(e,id)
    fetchUserAddToCart()
    navigate("/cart")
        }
  }

  return (
    <div className='container pt-4 sm:mt-8 sm:mx-auto ml-2 mt-14'>

      <div className='min-h-[200px] flex flex-col lg:flex-row gap-4'>
          {/***product Image */}
          <div className='h-96 flex flex-col lg:flex-row-reverse gap-4'>

              <div className='h-[300px] w-[300px] lg:h-96 lg:w-96 bg-white relative  outline-offset-2 outline-4 outline-slate-600 outline-double'>
                  <img src={activeImage} className='h-full w-full object-fill mix-blend-multiply' onMouseMove={handleZoomImage} onMouseLeave={handleLeaveImageZoom} alt=''/>

                    {/**product zoom */}
                    {
                      zoomImage && (
                        <div className='hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[400px] bg-slate-200 p-1 -right-[520px] top-0'>
                          <div
                            className='w-full h-full min-h-[400px] min-w-[500px] mix-blend-multiply '
                            style={{
                              background : `url(${activeImage})`,
                              backgroundRepeat : 'no-repeat',
                              backgroundPosition : `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}% `,
                              backgroundSize: '400%'
                            
                            }}
                          >
    
                          </div>
                        </div>
                      )
                    }
                  
              </div>

              <div className='h-full'>
                  {/* {
                    loading ? (
                      <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                        {
                          productImageListLoading.map((el,index) =>{
                            return(
                              <div className='h-20 w-20 bg-slate-200 rounded animate-pulse' key={"loadingImage"+index}>
                              </div>
                            )
                          })
                        }
                      </div>
                      
                    ) : ( */}
                      <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                        {
                          data?.productImage?.map((imgURL,index) =>{
                            return(
                              <div className='h-24 w-20 bg-slate-200 rounded p-1' key={imgURL}>
                                <img src={imgURL}  alt='' className='w-full h-full object-fill mix-blend-multiply cursor-pointer' onMouseEnter={()=>handleMouseEnterProduct(imgURL)}  onClick={()=>handleMouseEnterProduct(imgURL)}/>
                              </div>
                            )
                          })
                        }
                      </div>
                    
                  
              </div>
          </div>

           {/***product details */}
           {
            // loading ? (
            //   <div className='grid gap-1 w-full'>
            //     <p className='bg-slate-200 animate-pulse  h-6 lg:h-8 w-full rounded-full inline-block'></p>
            //     <h2 className='text-2xl lg:text-4xl font-medium h-6 lg:h-8  bg-slate-200 animate-pulse w-full'></h2>
            //     <p className='capitalize text-slate-400 bg-slate-200 min-w-[100px] animate-pulse h-6 lg:h-8  w-full'></p>

            //     <div className='text-red-600 bg-slate-200 h-6 lg:h-8  animate-pulse flex items-center gap-1 w-full'>
    
            //     </div>

            //     <div className='flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1 h-6 lg:h-8  animate-pulse w-full'>
            //       <p className='text-red-600 bg-slate-200 w-full'></p>
            //       <p className='text-slate-400 line-through bg-slate-200 w-full'></p>
            //     </div>

            //     <div className='flex items-center gap-3 my-2 w-full'>
            //       <button className='h-6 lg:h-8  bg-slate-200 rounded animate-pulse w-full'></button>
            //       <button className='h-6 lg:h-8  bg-slate-200 rounded animate-pulse w-full'></button>
            //     </div>

            //     <div className='w-full'>
            //       <p className='text-slate-600 font-medium my-1 h-6 lg:h-8   bg-slate-200 rounded animate-pulse w-full'></p>
            //       <p className=' bg-slate-200 rounded animate-pulse h-10 lg:h-12  w-full'></p>
            //     </div>
            //   </div>
            // ) : 
            // (
              <div className='flex flex-col gap-1 lg:ml-0 ml-2 lg:mt-0 mt-6'>
                <p className='bg-red-200 text-red-600 px-2 rounded-full inline-block w-fit'>{data?.brandName}</p>
                <h2 className='text-2xl lg:text-4xl font-bold text-white'>{data?.productName}</h2>
                <p className='capitalize text-slate-400'>{data?.category}</p>

               

                <div className='flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1'>
                  <p className='text-red-600'>{displayINRCurrency(data.sellingPrice)}</p>
                  <p className='text-slate-400 line-through'>{displayINRCurrency(data.price)}</p>
                </div>

                <div className='flex items-center gap-3 my-2'>
                  <button className='border-2 border-gray-600 rounded px-3 py-1 min-w-[120px] bg-white hover:bg-black hover:text-white active:translate-y-1 text-black font-medium ' onClick={(e)=>handleBuyProduct(e,data?._id,setCartProductCount)}>Buy</button>
                  <button className='border-2 border-gray-600 rounded px-3 py-1 min-w-[120px] font-medium bg-white hover:bg-black hover:text-white active:translate-y-1 text-black' onClick={(e)=>handleAddToCart(e,data?._id, setCartProductCount)}>Add To Cart</button>
                </div>

                <div>
                  <p className='text-white font-medium text-lg my-1'>Description : </p>
                  <p className='text-slate-400 text-0.5lg'>{data?.description}</p>
                </div>
              </div>
            
           }

      </div>



      {
        data.category && (
          <CategroyWiseProductDisplay category={data?.category} heading={"Recommended Product"}/>
        )
      }
     

    </div>
  )
}

export default ProductDetails