import React from 'react'
import './loading.css'
export default function Loading() {
  return (

<div class="loader-container">
  <div class="loader"></div>
</div>
  )
}
