import React, { useContext,  useState,useEffect } from 'react'
import scrollTop from '../helpers/scrollTop'
import displayINRCurrency from '../helpers/displayCurrency'
import Context from '../context'
import addToCart from '../helpers/addToCart'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import addToCartGuest from '../helpers/addToCartGuest'
import Loading from './Loading'
const VerticalCard = ({ loading, data = [], sortBy }) => {
  const { fetchUserAddToCart } = useContext(Context);
  const loadingList = new Array(4).fill(null)
  const { setCartProductCount } = useContext(Context);
  
    const handleAddToCart = async (e, id) => {
      if (!localStorage.getItem("authToken")) {
      console.log("called from verticalcard component");
      await addToCartGuest(e, id,setCartProductCount);
    }
    else{
      await addToCart(e,id);
      
      fetchUserAddToCart(); 
    }
    
    };

  const [currentImages, setCurrentImages] = useState([]);

  useEffect(() => {
    const initialImages = data.map(product => product?.productImage[0]);
    setCurrentImages(initialImages);

    data.forEach(product => {
      product.productImage.forEach(imageUrl => {
        const img = new Image();
        img.src = imageUrl;
      });
    });
  }, [data, sortBy]); // Update images whenever data or sortBy changes

  const handleMouseEnter = (index, nextImage) => {
    setCurrentImages(prevImages => {
      const newImages = [...prevImages];
      newImages[index] = nextImage;
      return newImages;
    });
  };

  const handleMouseLeave = (index, firstImage) => {
    setCurrentImages(prevImages => {
      const newImages = [...prevImages];
      newImages[index] = firstImage;
      return newImages;
    });
  };

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-[repeat(auto-fit,minmax(260px,300px))] justify-center md:justify-evenly md:gap-5 overflow-x-scroll scrollbar-none transition-all h-full'>
      {
        
        loading ? (
          loadingList.map((product,index)=>{
              return(
                  <div className='w-full min-w-[280px]   md:min-w-[320px] max-w-[280px] md:max-w-[320px] mx-4 rounded-sm shadow outline-double mt-2 mb-4 outline-1 outline-amber-300' key={index}>
                      <div className='h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse'>
                      </div>
                      <div className='p-4 grid gap-3'>
                          <div className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full'><Loading/></div>
                         
                          <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full py-2'></p>
                          <div className='flex gap-3'>
                              <p className='text-red-600 font-medium p-1 animate-pulse rounded-full  w-full  py-2'></p>
                              <p className='text-slate-500 line-through p-1 animate-pulse rounded-fullw-full  py-2'></p>
                          </div>
                          <button className='text-sm text-white px-3  rounded-full  py-2 animate-pulse'></button>
                      </div>
                  </div>
              )
          })
      ) : (
        data.map((product, index) => (
          <Link
            to={"/product/" + product?._id}
            className='w-fit min-w-[260px] md:min-w-[300px] max-w-[280px] md:max-w-[320px] mx-auto bg-black rounded-sm shadow h-auto outline-double my-2  outline-1 outline-amber-300'
            onClick={scrollTop}
            key={product?._id} // Add a key for better performance
          >
            <div
              className='bg-slate-200 h-80 min-w-[260px]  md:min-w-[300px] flex justify-center items-center'
              onMouseEnter={() => handleMouseEnter(index, product?.productImage[1])}
              onMouseLeave={() => handleMouseLeave(index, product?.productImage[0])}
            >
              <img
                src={currentImages[index]} 
                className='object-fill h-full w-full hover:scale-120 transition-all' 
                alt={product?.productName}
              />
            </div>
            <div className='p-4 grid gap-3 bg-zinc-900'>
              <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-white'>
                {product?.productName}
              </h2>
              <p className='capitalize text-slate-300'>{product?.category}</p>
              <div className='flex gap-3'>
                <p className='text-red-600 font-medium'>
                  {displayINRCurrency(product?.sellingPrice)}
                </p>
                <p className='text-slate-500 line-through'>
                  {displayINRCurrency(product?.price)}
                </p>
              </div>
              <button
                className='text-sm bg-white hover:bg-black text-black hover:text-white px-3 py-0.5 rounded-full active:translate-y-1'
                onClick={(e) => handleAddToCart(e, product?._id,setCartProductCount)}
              >
                Add to Cart
              </button>
            </div>
          </Link>
        ))
      )}
    </div>
  );
}

export default VerticalCard;