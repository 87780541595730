import React, { useContext, useState } from 'react'
import loginIcons from '../assest/signin.gif'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import Context from '../context';
import './LoginSignupStyle.css';
import Logo from '../assest/rustam_white_wo_border.png';
const Login = () => {
    const [showPassword,setShowPassword] = useState(false)
    const [data,setData] = useState({
        email : "",
        password : ""
    })
    const navigate = useNavigate()
    const { fetchUserDetails, fetchUserAddToCart } = useContext(Context)
    const token = localStorage.getItem('authToken');
    const handleOnChange = (e) =>{
        const { name , value } = e.target

        setData((preve)=>{
            return{
                ...preve,
                [name] : value
            }
        })
    }


    const handleSubmit = async(e) =>{
        e.preventDefault()

        const dataResponse = await fetch(SummaryApi.signIn.url,{
            method : SummaryApi.signIn.method,
            credentials : 'include',
            headers : {
              'Authorization': `Bearer ${token}`,
                "content-type" : "application/json"
            },
            body : JSON.stringify(data)
        })

        const dataApi = await dataResponse.json()

        if(dataApi.success){
            toast.success(dataApi.message)
            localStorage.setItem('authToken', dataApi.data.token);
            navigate('/')
            fetchUserDetails()
            fetchUserAddToCart()
        }

        if(dataApi.error){
            toast.error(dataApi.message)
        }

    }

    
  return (
    <section id='login'>
    
<div className="flex items-center justify-center flex-col mt-16 h-min w-full relative z-[1]">
  <form method="post" className="form" onSubmit={handleSubmit}>
    <div className="form-body">
      <div className="welcome-lines">
        <div className="welcome-line-1">
        <img src={Logo} alt="Rustam" className=' w-auto h-24 mx-auto' />
        </div>
        <div className="welcome-line-2">Welcome Back</div>
      </div>
      <div className="input-area">
        <div className="form-inp">
          <input  type='email' 
                                    placeholder='Email Address' 
                                    name='email'
                                    value={data.email}
                                    onChange={handleOnChange}/>
        </div>
        <div className="form-inp flex p-2">
          <input type={showPassword ? "text" : "password"} 
                                    placeholder='enter password'
                                    value={data.password}
                                    name='password' 
                                    onChange={handleOnChange} />
                                    <div className='cursor-pointer text-xl' onClick={()=>setShowPassword((preve)=>!preve)}>
                                    <span>
                                        {
                                            showPassword ? (
                                                <FaEyeSlash/>
                                            )
                                            :
                                            (
                                                <FaEye/>
                                            )
                                        }
                                    </span>
                                </div>
        </div>
      </div>
      <div className="submit-button-cvr">
        <button className="submit-button" type="submit">Login</button>
      </div>
    </div>

  </form>
  <p className='text-lg my-2 mx-auto text-center text-white border border-xl border-x-0 border-t-0 px-2'>Don't have account ? <Link to={"/sign-up"} className=' text-red-600 hover:text-red-700 hover:underline font-bold'>Sign up</Link></p>

  </div>



        {/* </div> */}
    </section>
  )
}

export default Login