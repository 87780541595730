import React, { useContext, useEffect, useRef, useState } from 'react'
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct'
import displayINRCurrency from '../helpers/displayCurrency'
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import addToCart from '../helpers/addToCart'
import Context from '../context'
import addToCartGuest from '../helpers/addToCartGuest'
import scrollTop from '../helpers/scrollTop'
import Loading from './Loading'
import "./scrollBar.css"
const VerticalCardProduct = ({category, heading}) => {
    const [data,setData] = useState([])
    const [loading,setLoading] = useState(true)
    const loadingList = new Array(4).fill(null)
    // const [scroll,setScroll] = useState(0)
    const scrollElement = useRef()
    const { setCartProductCount } = useContext(Context); 
    const { fetchUserAddToCart } = useContext(Context)

    const handleAddToCart = async (e, id, setCartProductCount) => {
      if (!localStorage.getItem("authToken")) {
      console.log("called from verticalcard component");
      await addToCartGuest(e, id, setCartProductCount);
    }
    else{
      await addToCart(e,id);
      
      fetchUserAddToCart(); 
    }
    
    };
    
    const fetchData = async() =>{
        setLoading(true)
        const categoryProduct = await fetchCategoryWiseProduct(category)
        setLoading(false)

        
        setData(categoryProduct?.data)
    }
    const [currentImages, setCurrentImages] = useState(
        data.map((product) => product?.productImage[0]) 
      );
    
      const handleMouseEnter = (index, nextImage) => {
   
        setCurrentImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = nextImage;
          return newImages;
        });
      };
    
      const handleMouseLeave = (index, firstImage) => {
   
        setCurrentImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = firstImage;
          return newImages;
        });
      };

    useEffect(()=>{
        fetchData()
    },[])
    useEffect(() => {
 
        const initialImages = data.map(product => product?.productImage[0]);
        setCurrentImages(initialImages);
    

        data.forEach(product => {
          product.productImage.forEach(imageUrl => {
            const img = new Image();
            img.src = imageUrl;
          });
        });
      }, [data]);
    // const scrollRight = () =>{
    //     scrollElement.current.scrollLeft += 300
    // }
    // const scrollLeft = () =>{
    //     scrollElement.current.scrollLeft -= 300
    // }


  return (
    <div className='container mx-auto px-4 my-2 relative'>

            <h2 className='text-2xl font-semibold text-amber-300 py-4'>{heading}</h2>

                
           <div className='flex items-center  gap-4 md:gap-6 overflow-x-scroll transition-all' ref={scrollElement}>
{/* 
            <button  className='bg-white shadow-md rounded-full p-1 absolute left-0 text-lg hidden md:block' onClick={scrollLeft}><FaAngleLeft/></button>
            <button  className='bg-white shadow-md rounded-full p-1 absolute right-0 text-lg hidden md:block' onClick={scrollRight}><FaAngleRight/></button>  */}

           {

                loading ? (
                    loadingList.map((product,index)=>{
                        return(
                            <div className='w-full min-w-[280px]   md:min-w-[320px] max-w-[280px] md:max-w-[320px] mx-4 rounded-sm shadow outline-double mt-2 mb-4 outline-1 outline-amber-300' key={index}>
                                <div className='h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse'>
                                </div>
                                <div className='p-4 grid gap-3'>
                                    <div className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full'><Loading/></div>
                                   
                                    <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full py-2'></p>
                                    <div className='flex gap-3'>
                                        <p className='text-red-600 font-medium p-1 animate-pulse rounded-full  w-full  py-2'></p>
                                        <p className='text-slate-500 line-through p-1 animate-pulse rounded-fullw-full  py-2'></p>
                                    </div>
                                    <button className='text-sm text-white px-3  rounded-full  py-2 animate-pulse'></button>
                                </div>
                            </div>
                        )
                    })
                ) : ( <>
                    {data.map((product, index) => (
                      <Link 
                        to={"product/" + product?._id} 
                        onClick={scrollTop} 
                        key={index} 
                        className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] mx-4 bg-black rounded-lg shadow outline-double mt-2 mb-4 outline-1 outline-amber-300'
                      >
                        <div 
                          className='bg-slate-200 h-80 min-w-[280px] md:min-w-[145px] flex justify-center items-center rounded-lg'
                          onMouseEnter={() => handleMouseEnter(index, product?.productImage[1])}
                          onMouseLeave={() => handleMouseLeave(index, product?.productImage[0])}
                        >
                          <img 
                            src={currentImages[index]} 
                            className='object-fill h-full w-full hover:scale-120 transition-all rounded-lg' 
                            alt={product?.productName}
                          />
                        </div>
                        <div className='p-4 grid gap-3 bg-zinc-900'>
                          <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-white'>
                            {product?.productName}
                          </h2>
                          <p className='capitalize text-slate-400'>{product?.category}</p>
                          <div className='flex gap-3'>
                            <p className='text-red-600 font-medium'>{displayINRCurrency(product?.sellingPrice)}</p>
                            <p className='text-slate-500 line-through'>{displayINRCurrency(product?.price)}</p>
                          </div>
                          <button 
                            className='text-sm bg-white hover:bg-black hover:text-white active:translate-y-1 text-black px-3 py-0.5 rounded-full' 
                            onClick={(e) => handleAddToCart(e, product?._id, setCartProductCount)}
                          >
                            Add to Cart
                          </button>
                        </div>
                      </Link>
                    ))}
                  </>
                )
                
            }
           </div>
            

    </div>
  )
}

export default VerticalCardProduct