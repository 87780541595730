import React, { useEffect, useState } from 'react'

import banner from '../assest/Banner 3.png'


const BannerImage = () => {
   

 
  return (
    <div className='container mx-auto sm:p-4 rounded'>
    <div className=' md:h-72 w-full bg-gray-925 relative sm-h56'>
        

        {/* Desktop and Tablet Version */}
        <div className='hidden md:flex h-full w-full overflow-hidden' style={{ borderRadius: '20px' }}>
            <div className='w-full h-auto min-w-full min-h-full transition-transform duration-300' >
                <img src={banner} className='w-full h-full object-fill' style={{ clipPath: 'inset(0 0 0 0)' }} />
            </div>
        </div>

        {/* Mobile Version */}
        <div className='flex h-full w-full overflow-hidden md:hidden'>
            <div className='w-full h-full min-w-full min-h-full transition-transform duration-300'>
                <img src={banner} className='w-full h-32 object-cover' style={{ clipPath: 'inset(0 0 0 0)' }} />
            </div>
        </div>
    </div>
</div>

  )
}

export default BannerImage