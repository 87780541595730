import React from 'react'
import Logo from '../assest/rustam_logo_white.png'
import Whatsapp from '../assest/whatsapp2.png'
const Footer = () => {
  return (
    <section className=" bg-zinc-900 w-auto py-8">
    <div className="px-4  sm:px-6 lg:px-8 ">
    <div className="grid  gap-y-16 md:grid-cols-2 sm:grid-cols-1 grid-cols-1">

    
            <div className="col-span-1 flex flex-col items-start">
                <img className="w-80 h-28" src={Logo} alt="" />
                <p className=" text-xl leading-relaxed text-gray-500 mt-7 text-bol font-bold	">Rustam is a dynamic startup brand redefining the world of accessories for both men and women. With a name inspired by legendary strength and sophistication, Rustam is dedicated to curating a collection of accessories that exude timeless elegance and modern flair.</p>
                <p className=" text-xl leading-relaxed text-gray-500 mt-7 text-bol font-bold	">At Rustam, we understand that accessories are more than just additions to your outfit; they are reflections of your individuality and a celebration of your unique journey. Whether you’re dressing for a formal occasion or adding a touch of sophistication to your everyday look, our pieces offer a perfect blend of classic charm and contemporary edge.</p>
              
            </div>

       
            <div className="col-span-1 flex flex-col items-end pr-10 text-lg text-gray-500">
    <p className="text-3xl font-bold size-15 tracking-widest uppercase mr-20 ">Contact Us</p>
    <ul className="mt-8 space-y-4  text-lg  font-bold">
        <li className="flex items-center space-x-4">
        <a href='https://wa.me/923354252666' target='_blank'><img src={Whatsapp} className="w-11 h-11 hover:bg-green-400 focus:bg-green-700 cursor-pointer" alt="Whatsapp" /> </a> 
            <div>
               <p><a href='https://wa.me/923354252666' target='_blank'>+923354252666 </a> </p> 
                <a href='https://wa.me/923332012009' target='_blank'>+923332012009 </a>
            </div>
        </li>
        <li className="flex items-center space-x-4">
            <img src="https://img.icons8.com/?size=100&id=baRhMh2wmjbT&format=png&color=FFFFFF" className="w-11 h-11" alt=""/>
            <p>rustamstore.org@gmail.com</p>
        </li>
                <li className="flex items-center space-x-4">
                        <a href="https://www.instagram.com/rustamstore_/" target='_blank' title="instagram" className="flex items-center justify-center size-12 text-white transition-all duration-200  rounded-full w-13 h-13 hover:bg-pink-600 focus:bg-pink-900">
                        <svg className="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                                <circle cx="16.806" cy="7.207" r="1.078"></circle>
                                <path
                                    d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"
                                ></path>
                            </svg>
                            
                        </a>
                        <a href="https://www.instagram.com/rustamstore_" target='_blank' title="tiktok" >rustamstore_</a>

                    </li>
                    <li className="flex items-center space-x-4">
                        <a href="https://www.tiktok.com/@rustamstore_" target='_blank' title="tiktok" className="flex size-12 items-center justify-center text-white transition-all duration-200 rounded-sm  w-13 h-12 hover:bg-rose-700 focus:bg-pink-900">
                        <img src="https://img.icons8.com/?size=100&id=84521&format=png&color=FFFFFF" className="w-11 h-11" alt=""/>
                        </a>
                        
                        <a href="https://www.tiktok.com/@rustamstore_" target='_blank' title="tiktok" >rustamstore_</a>
                    </li>
    </ul>
</div>

        </div>

        <hr className="mt-8 mb-10 border-gray-600" />
        <p className="text-lg  text-center text-gray-600">© Copyright 2024, All Rights Reserved by Rustam Store</p>
    </div>
</section>


  )
}

export default Footer