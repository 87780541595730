import React, { useContext, useState, useEffect } from 'react'
import Logo from './Logo'
import { GrSearch } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SummaryApi from '../common';
import { toast } from 'react-toastify'
import { setUserDetails } from '../store/userSlice';
import ROLE from '../common/role';
import Context from '../context';
import scrollTop from '../helpers/scrollTop'
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage the mobile menu
  const user = useSelector(state => state?.user?.user)
  const dispatch = useDispatch()
  // const [menuDisplay,setMenuDisplay] = useState(false)
  const context = useContext(Context)
  const navigate = useNavigate()
  const searchInput = useLocation()
  const URLSearch = new URLSearchParams(searchInput?.search)
  const searchQuery = URLSearch.getAll("q")
  const [search,setSearch] = useState(searchQuery)
  const location = useLocation();
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const handleLogout = async() => {
    const fetchData = await fetch(SummaryApi.logout_user.url,{
      method : SummaryApi.logout_user.method,
      credentials : 'include'
    })

    const data = await fetchData.json()
    localStorage.removeItem('authToken')
    localStorage.removeItem('products')
    context.cartProductCount = "0"
    if(data.success){
      toast.success(data.message)
      dispatch(setUserDetails(null))
      navigate("/")
    }

    if(data.error){
      toast.error(data.message)
    }

  }
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); 
  };
  const displayMenuClicked = ()=>{
    {user?.role === ROLE.ADMIN && (
      navigate("/admin-panel/all-products")
    )}
    {user?.role !== ROLE.ADMIN && (
      navigate("/user-panel/all-user-orders")
    )}
  }
  // const handleSearch = (e)=>{
  //   const { value } = e.target
  //   setSearch(value)
  //   scrollTop()
  //   if(value){
  //     navigate(`/search?q=${value}`)
  //   }else{
  //     navigate("/search")
  //   }
  // }
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    scrollTop();

    // Clear any previously set timeout when user types
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to trigger after 500ms (adjustable) of inactivity
    const timeout = setTimeout(() => {
      if (value) {
        navigate(`/search?q=${value}`);
      } else {
        navigate("/search");
      }
    }, 500); // Wait 500ms after typing stops to trigger search

    // Store the timeout so it can be cleared on the next keystroke
    setDebounceTimeout(timeout);
  };
  return (
    <header className='h-16 shadow-md bg-white fixed w-full z-40'>
    <div className='h-full container mx-auto flex items-center px-4 justify-between'>
      
      {/* Logo */}
      <div className=' '>
        <Link to={"/"} onClick={scrollTop}><Logo w={90} h={60} /></Link>
      </div>
  
      {/* Search Bar */}
      <div className={`flex items-center w-full justify-between max-w-lg  border rounded-full focus-within:shadow pl-2 bg-slate-200 border-black 
        ${user?._id ? "hidden lg:flex" : "hidden lg:flex"}`
      }>
        <input type='text' placeholder='search product here...' className='w-full outline-none bg-slate-200' onChange={handleSearch} value={search} />
        <div className='text-lg min-w-[50px] h-8 bg-black flex items-center justify-center rounded-r-full text-white'>
          <GrSearch />
        </div>
      </div>
  
      {/* Mobile Search Bar (Guest/Logged-In) */}
      <div className='flex items-center w-full justify-between max-w-sm border rounded-full focus-within:shadow pl-2 bg-slate-200 border-black mx-3 lg:hidden'>
        <input type='text' placeholder='search...' className='w-full outline-none bg-slate-200' onChange={handleSearch} value={search} onClick={scrollTop} />
        <div className='text-lg min-w-[50px] h-8 bg-black flex items-center justify-center rounded-r-full text-white'>
          <GrSearch />
        </div>
      </div>
  
      {/* User Actions */}
      <div className=' items-center gap-3 sm:gap-7 hidden sm:flex'> {/* Hide on small screens */}
        {user?._id &&  (
          <div
            className='text-3xl cursor-pointer relative flex justify-center max-w-10 max-h-10 min-h-8 min-w-8'
            onClick={ displayMenuClicked}
          >
            {user?.profilePic ? (
              <img src={user?.profilePic} className=' rounded-full' alt={user?.name} />
            ) : (
              <FaRegCircleUser />
            )}
          </div>
        )}
  
        {/* Cart */}
        {user?._id && location.pathname !== '/cart' && (
          <Link to={"/cart"} onClick={scrollTop} className='text-2xl relative'>
            <span><FaShoppingCart /></span>
            <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
              <p className='text-sm'>{context?.cartProductCount}</p>
            </div>
          </Link>
        )}

{!user?._id && location.pathname !== '/Guestcart' && (
          <Link to={"/Guestcart"} onClick={scrollTop} className='text-2xl relative'>
            <span><FaShoppingCart /></span>
            <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
              <p className='text-sm'>{context?.cartProductCount}</p>
            </div>
          </Link>
        )}
  
        {/* Login/Logout Button */}
        <div>
          {user?._id ? (
            <button onClick={handleLogout} className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700 w-auto'>Logout</button>
          ) : (
            <Link to={"/login"} onClick={scrollTop} className='px-3 py-1 rounded-full text-white bg-black hover:bg-zinc-700'>Login</Link>
          )}
        </div>
      </div>
  
      {/* Menu Icon for small screens */}
      <div className="sm:hidden flex items-center"> {/* Show only on small screens */}
        <button onClick={toggleMenu} className="text-3xl">
          <FaBars />
        </button>
      </div>
    </div>
  
    {/* Mobile Menu */}
    {isMenuOpen && (
      <div className="sm:hidden bg-white shadow-lg rounded-lg p-4 absolute top-16 right-0 w-auto z-50 outline outline-amber-300">
        {user?._id && (
          <div className='flex items-center flex-col justify-center mb-0 cursor-pointer' onClick={ displayMenuClicked}>
            {user?.profilePic ? (
              <img src={user?.profilePic} className='rounded-full w-10 h-10' alt={user?.name} />
              
            ) : (
              <FaRegCircleUser className="text-4xl" />
            )}
            <p className='underline'> {user?.role===ROLE.ADMIN ? "Admin Panel" : "User Panel"}</p>
          </div>
          
        )}
         <hr className='w-full mt-1' />
        <ul className="flex flex-col items-center">
          <li className=''>
            
            {user?._id && location.pathname !== '/cart' && (
          <Link to={"/cart"} onClick={scrollTop} className='text-lg relative flex items-center mt-4'>
            <FaShoppingCart className='h-6 w-6'/>
              <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
                <p className='text-sm'>{context?.cartProductCount}</p>
              </div>
          </Link>
        )}
            {!user?._id && location.pathname !== '/Guestcart' && (
          <Link to={"/Guestcart"} onClick={scrollTop} className='text-lg relative flex items-center mt-4'>
            <FaShoppingCart className='h-6 w-6'/>
              <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
                <p className='text-sm'>{context?.cartProductCount}</p>
              </div>
          </Link>
        )}
          </li>
          <hr className='w-full mt-2' />
          <li className='mt-4'>
            {user?._id ? (
              <button onClick={handleLogout} className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700 w-auto '>Logout</button>
            ) : (
              <Link to={"/login"} onClick={scrollTop} className='px-3 py-1 rounded-full text-white bg-black hover:bg-zinc-700 '>Login</Link>
            )}
            
          </li>
        </ul>
      </div>
    )}
  
    {/* Promotional Banner */}
    {location.pathname !== '/user-panel/all-user-orders' && (
      <div className="relative w-full h-auto min-h-[25px] bg-red-600 mb-5 text-black text-center font-bold text-sm flex items-center font-serif justify-center px-4 py-2 md:py-0 md:px-0 
  lg:mb-5 lg:top-0">
    <p className="leading-snug">
      FLAT 15% OFF ON ALL PRODUCTS 
    </p>
  </div> )}
  
  </header>
  

  )
}

export default Header
